import React from 'react';
import Icons from '../../assets/images/icons.svg';

const BackButton = (props) => {
	return (
		<button onClick={props.onClick} className='text-gray-900 text-sm text-left py-1 px-4 z-30'>
			<svg style={{ width: 20, height: 20 }} className='fill-current text-gray-600 inline-block mr-1'>
				<use xlinkHref={`${Icons}#icon-chevron-left`}></use>
			</svg>
			{props.children}
		</button>
	);
};

export default BackButton;

import types from './types';

const changeWizardStep = (step) => ({
	type: types.CHANGE_WIZARD_STEP,
	payload: step,
});

const saveData = (data) => ({
	type: types.SAVE_DATA,
	payload: data,
});

const submitEmail = (data) => ({
	type: types.SUBMIT_EMAIL,
	payload: data,
});

const fetchCompanyFulfilled = (data) => ({
	type: types.FETCH_COMPANY_FULFILLED,
	payload: data,
});

export default {
	changeWizardStep,
	fetchCompanyFulfilled,
	saveData,
	submitEmail,
};

import types from './types';
import { combineReducers } from 'redux';

const initialState = {
	email: '',
	loading: false,
	candidates: [],
	company: {
		name: '',
		coc: '',
		address: '',
	},
};

const data = (state = initialState, action) => {
	switch (action.type) {
		case types.SAVE_DATA:
			return { ...state, ...action.payload };

		case types.SUBMIT_EMAIL:
			return { ...state, ...action.payload, loading: true };

		case types.FETCH_COMPANY_FULFILLED:
			return { ...state, candidates: action.payload.candidates, loading: false };

		default:
			return state;
	}
};

const activeWizardStep = (state = 1, action) => {
	if (action.type === types.CHANGE_WIZARD_STEP) {
		switch (action.payload) {
			case types.BACK:
				return state > 1 ? state - 1 : state;
			default:
				return action.payload;
		}
	}
	return state;
};

const reducers = combineReducers({
	data,
	activeWizardStep,
});

export default reducers;

import React from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/layout';
import OnboardingWizard from '../components/wizards/onboarding';
import Seo from '../components/seo';
import Topbar from '../components/wizards/onboarding/topbar';
import BackButton from '../components/buttons/back';
import { actions, types } from '../state/modules/onboarding';

const OnboardingPage = () => {
	const dispatch = useDispatch();

	return (
		<Layout className='flex flex-col'>
			<Seo title='Home' />
			<header className='flex flex-col sm:flex-row w-full'>
				<Topbar />
			</header>
			<div className='flex w-full align-left pt-4'>
				<BackButton onClick={() => dispatch(actions.changeWizardStep(types.BACK))}>back</BackButton>
			</div>
			<main className='flex container align-middle' style={{ width: '630px' }}>
				<OnboardingWizard />
			</main>
		</Layout>
	);
};

export default OnboardingPage;

import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useSiteMetadata } from '../../hooks';
import Icons from '../../assets/images/icons.svg';

const Navbar = () => {
	const [menuClass, setMenuClass] = useState('hidden');
	const { menu } = useSiteMetadata();

	function toggleMenu() {
		const updateMenuClass = menuClass === 'bg-gray-200' ? 'hidden' : 'bg-gray-200';
		setMenuClass(updateMenuClass);
	}

	function renderMenuItems() {
		return (
			<ul className='list-reset lg:flex justify-end flex-1 items-center'>
				{menu.map((item) => (
					<li className='mr-3 text-gray-600' key={item.label}>
						<Link
							className='animated fadeIn text-base inline-block py-2 px-4 no-underline hover:text-pink-600'
							to={item.path}
							activeClassName='text-gray-800'>
							{item.label}
						</Link>
					</li>
				))}
			</ul>
		);
	}

	return (
		<nav className='flex items-center justify-between flex-wrap bg-white w-full'>
			<div className='flex items-center flex-shrink-0 text-white mr-6 p-4 pl-6'>
				<Link className='no-underline hover:no-underline' to='/'>
					<svg className='navbar-logo' alt='Insify' style={{ width: 81, height: 33 }}>
						<use xlinkHref={`${Icons}#icon-logo`}></use>
					</svg>
				</Link>
			</div>

			<div className='block lg:hidden'>
				<button
					onClick={toggleMenu}
					className='flex items-center px-6 py-2 text-gray-500 border-gray-600 hover:border-white'>
					<svg className='fill-current h-6 w-6' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
						<title>Menu</title>
						<path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
					</svg>
				</button>
			</div>

			<div
				className={`w-full flex-grow lg:flex lg:items-center lg:w-auto lg:block xs:block pt-6 lg:pt-0 ${menuClass}`}>
				{renderMenuItems()}
			</div>
		</nav>
	);
};

export default Navbar;

import React from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Topbar from '../components/wizards/onboarding/topbar';
import { actions } from '../state/modules/onboarding';
import PlaceholderImage from '../assets/images/placeholder-finished.png';

const OnboardingAdvisePage = () => {
	const dispatch = useDispatch();
	dispatch(actions.changeWizardStep(6));

	return (
		<Layout className='flex flex-col'>
			<Seo title='Home' />
			<header className='flex flex-col sm:flex-row w-full'>
				<Topbar />
			</header>
			<div className='absolute z-0 ins-placeholder-wrapper text-center' style={{ backgroundColor: '#cb0264' }}>
				<div className='ins-placeholder-finished animated fadeIn slow' style={{ backgroundImage: `url(${PlaceholderImage})` }}></div>
			</div>
		</Layout>
	);
};

export default OnboardingAdvisePage;

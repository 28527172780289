import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Navbar from '../components/navbar';
import HomePlaceholderImage from '../components/home/placeholder-image';

export default () => (
	<Layout>
		<Seo title='Home' />
		<header className='flex flex-col sm:flex-row w-full'>
			<Navbar />
		</header>
		<HomePlaceholderImage />
	</Layout>
);

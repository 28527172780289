import React from 'react';

const DefaultButton = (props) => {
	const disabledClasses = props.disabled ? 'opacity-50 cursor-not-allowed' : '';
	return (
		<button
			onClick={props.onClick}
			type={props.type || 'button'}
			disabled={props.disabled || false}
			style={{ width: props.width || 'auto', height: props.height || '35px' }}
			className={`bg-yellow-500 text-gray-900 text-sm rounded text-center font-bold py-1 px-4 hover:bg-yellow-600 ${disabledClasses}`}>
			{props.children}
		</button>
	);
};

export default DefaultButton;

import React from 'react';
import { useSiteMetadata } from '../hooks';
import Header from '../components/header';
import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutPage = () => {
	const { title } = useSiteMetadata();

	return (
		<Layout>
			<Seo title='About' />
			<Header headerText={`About: ${title}`} />
			<p>Such wow. Very React.</p>
		</Layout>
	);
};

export default AboutPage;

import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../state/modules/onboarding';
import DefaultButton from '../../buttons/default';

const OnboardingWizardStep1 = () => {
	const onboarding = useSelector((state) => state.onboarding);
	const { register, errors, formState, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues: onboarding.data,
	});
	const dispatch = useDispatch();
	const onSubmit = (data) => {
		dispatch(actions.submitEmail({ email: data.email }));
		dispatch(actions.changeWizardStep(2));
	};

	return (
		<div className='flex w-full flex-col'>
			<div className='animated fadeIn slow w-full text-center mt-6 mb-10'>
				<h1>To get started, please fill in your email</h1>
				<h4>
					We promise to not spam you or save your email, we only use it to save you some time filling in
					forms.
				</h4>
			</div>

			<form
				className='flex flex-col bg-white w-full shadow-md rounded px-8 pt-6 pb-8 mb-4'
				onSubmit={handleSubmit(onSubmit)}>
				<div className='mb-4 w-full'>
					<label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
						Email
					</label>
					<input
						className='ins-input-default w-full'
						name='email'
						ref={register({ required: true, pattern: /\S+@\S+\.\S+/i })}
						type='text'
						placeholder='Email'
					/>
					{errors.email && <p className='text-red-500 text-xs italic'>Please fill in your email.</p>}
				</div>
				<div className='flex items-center justify-between'>
					<DefaultButton type='submit' disabled={!formState.isValid}>
						Next
					</DefaultButton>
				</div>
			</form>
		</div>
	);
};

export default OnboardingWizardStep1;

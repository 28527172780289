import { navigate } from 'gatsby';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../state/modules/onboarding';
import Icons from '../../assets/images/icons.svg';

const GetQuoteForm = () => {
	const onboarding = useSelector((state) => state.onboarding);
	const { register, formState, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues: onboarding.data,
	});
	const dispatch = useDispatch();
	const onSubmit = (data) => {
		dispatch(actions.submitEmail({ email: data.email }));
		dispatch(actions.changeWizardStep(2));
		navigate('/onboarding');
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} className='ins-get-quote-form flex justify-between items-center'>
			<input
				className='w-full border-0 text-base bg-white'
				name='email'
				ref={register({ required: true, pattern: /\S+@\S+\.\S+/i })}
				type='text'
				placeholder='Your business email address'
			/>
			<button
				className={`flex items-center bg-pink-600 hover:bg-pink-700 text-white text-base font-bold py-2 px-4 rounded-full focus:outline-none focus:shadow-outline whitespace-no-wrap ${
					formState.isValid ? '' : 'cursor-not-allowed'
				}`}
				type='submit'
				disabled={!formState.isValid}>
				<svg style={{ width: 18, height: 20 }} className='fill-current inline-block mr-2'>
					<use xlinkHref={`${Icons}#icon-send`}></use>
				</svg>
				Get an accurate quote
			</button>
		</form>
	);
};

export default GetQuoteForm;

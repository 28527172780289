import React from 'react';
import PlaceholderImage from '../../assets/images/placeholder-home.png';
import PlaceholderImageBg from '../../assets/images/placeholder-home-bg.png';
import GetQuoteForm from './get-quote-form';

const HomePlaceholderImage = () => {
	return (
		<div className='ins-placeholder-home-bg' style={{ backgroundImage: `url(${PlaceholderImageBg})` }}>
			<div className='ins-placeholder-home' style={{ backgroundImage: `url(${PlaceholderImage})` }}>
				<GetQuoteForm />
			</div>
		</div>
	);
};

export default HomePlaceholderImage;

import React from 'react';
import { Link } from 'gatsby';
import Icons from '../../../assets/images/icons.svg';
import { useSelector } from 'react-redux';

const Topbar = () => {
	const { activeWizardStep } = useSelector((state) => state.onboarding);

	const renderStepLabel = (step, currentStep, label) => {
		if (step === 6 && currentStep < 6) {
			return '';
		}
		if (currentStep < step) {
			return (
				<div className='animated fadeIn flex flex-shrink-0 text-sm text-gray-500 items-center mx-5'>
					<span className='text-sm'>{label}</span>
				</div>
			);
		}
		if (currentStep > step) {
			return (
				<div className='animated fadeIn flex flex-shrink-0 text-sm text-gray-500 items-center mx-5'>
					<span className='text-sm'>{label}</span>
					<svg style={{ width: 16, height: 16 }} className='fill-current text-green-300 inline-block ml-1'>
						<use xlinkHref={`${Icons}#icon-check-circle`}></use>
					</svg>
				</div>
			);
		}
		return (
			<div className='animated fadeIn flex flex-shrink-0 text-sm text-gray-900 items-center mx-5'>
				<span className='text-sm'>{label}</span>
			</div>
		);
	};

	return (
		<nav className='z-40 flex justify-between items-center bg-white w-full'>
			<div className='flex-shrink-0 text-white mr-6 p-4 pl-6'>
				<Link className='no-underline hover:no-underline' to='/'>
					<svg className='navbar-logo' alt='Insify' style={{ width: 81, height: 33 }}>
						<use xlinkHref={`${Icons}#icon-logo`}></use>
					</svg>
				</Link>
			</div>
			<div className={'flex flex-grow justify-center'}>
				{renderStepLabel(2, activeWizardStep, '1. Confirm')}
				{renderStepLabel(3, activeWizardStep, '2. Advise')}
				{renderStepLabel(4, activeWizardStep, '3. Details')}
				{renderStepLabel(5, activeWizardStep, '4. Sign')}
				{renderStepLabel(6, activeWizardStep, "You're insured!")}
			</div>
			<div className='flex items-center flex-shrink-0'>
				<div className='animated fadeIn flex items-center border border-gray-300 rounded py-2 px-3 text-sm text-gray-900'>
					<svg style={{ width: 15, height: 14 }} className='fill-current text-gray-700 inline-block mr-1'>
						<use xlinkHref={`${Icons}#icon-heart`}></use>
					</svg>
					Save for later
				</div>
				<div className='animated fadeIn flex text-gray-900 py-1 px-4'>
					<svg style={{ width: 20, height: 22 }} className='fill-current inline-block mr-1'>
						<use xlinkHref={`${Icons}#icon-user`}></use>
					</svg>
				</div>
			</div>
		</nav>
	);
};

export default Topbar;

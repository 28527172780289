import React from 'react';
import { Provider } from 'react-redux';

import createStore from './store';

/**
 * Provider Wrapper
 * Instantiating store in `wrapRootElement` handler ensures:
 * - there is fresh store for each SSR page
 * - it will be called only once in browser, when React mounts
 */
export default ({ element }) => {
	const store = createStore();
	return <Provider store={store}>{element}</Provider>;
};

const BACK = 'state/onboarding/back';
const CHANGE_WIZARD_STEP = 'state/onboarding/change_wizard_step';
const FETCH_COMPANY_FULFILLED = 'state/onboarding/fetch_company_fulfilled';
const SAVE_DATA = 'state/onboarding/save_data';
const SUBMIT_EMAIL = 'state/onboarding/submit_email';

export default {
	BACK,
	CHANGE_WIZARD_STEP,
	FETCH_COMPANY_FULFILLED,
	SAVE_DATA,
	SUBMIT_EMAIL,
};

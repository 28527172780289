import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import { epics as onboardingEpics, reducers as onboardingReducers } from './onboarding';

const epics = combineEpics(onboardingEpics);

const reducers = combineReducers({
	onboarding: onboardingReducers,
});

export { epics, reducers };

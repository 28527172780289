import React from 'react';
import Layout from '../components/layout';
import MonitoringNavBar from '../components/monitoring/navbar';
import Seo from '../components/seo';

const MonitoringPage = () => {
	return (
		<Layout className='flex flex-col'>
			<Seo title='Underwriting' />
			<header className='flex flex-col sm:flex-row w-full'>
				<MonitoringNavBar />
			</header>
			<main className='flex container w-11/12 align-middle'>
				<div className='flex w-full flex-col'>
					<div className='animated fadeIn slow w-full text-center mt-16'>
						<h1>Underwriting</h1>
					</div>
					<iframe
						style={{ width: '100%', height: '2000px', border: 0 }}
						title='Insify Underwriting'
						src='https://insify.herokuapp.com/test/underwriting?domain=bloomon.nl'></iframe>
				</div>
			</main>
		</Layout>
	);
};

export default MonitoringPage;

import { ofType, combineEpics } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { mergeMap, map } from 'rxjs/operators';
import types from './types';
import actions from './actions';

const fetchCompanyEpic = (action$) =>
	action$.pipe(
		ofType(types.SUBMIT_EMAIL),
		mergeMap((action) => {
			return ajax
				.getJSON(`https://insify-api.herokuapp.com/company_search?q=${action.payload.email}`)
				.pipe(map((response) => actions.fetchCompanyFulfilled(response)));
		}),
	);

const epics = combineEpics(fetchCompanyEpic);

export default epics;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../state/modules/onboarding';
import CompaniesList from './companies-list';
import { navigate } from 'gatsby';
import DefaultButton from '../../buttons/default';
import DetailsCardImage from '../../../assets/images/placeholder-company-revenue-card.png';

const OnboardingWizardStep2 = () => {
	const { loading, candidates } = useSelector((state) => state.onboarding).data;
	const [showStep2b, setShowStep2b] = useState(false);
	const dispatch = useDispatch();

	const selectCompany = (company) => {
		dispatch(
			actions.saveData({
				company: {
					name: company.name,
					address: company.address,
					coc: company.identifiers[0].id,
				},
			}),
		);
		setShowStep2b(true);
	};

	const gotoAdvise = () => {
		navigate('/onboarding-advise');
	}

	const renderLoader = () => {
		setTimeout(() => {
			return <div className='w-full text-center mt-6 mb-10'>busy loading</div>;
		}, 1000);
	};

	const renderResultsHeader = () => {
		if (!showStep2b) {
			return (
				<div className='animated fadeIn slow w-full text-center mt-6 mb-10'>
					<h1>We found {candidates.length} companies based on your email, which one is the right one?</h1>
					<h4>We want to make sure we got the right details to create an fitting advise.</h4>
				</div>
			);
		}
	};

	const renderNoResultsHeader = () => {
		if (!showStep2b) {
			return (
				<div className='animated fadeIn slow w-full text-center mt-6 mb-10'>
					<h1>We couldn't find your company</h1>
					<h4>Please fill in the details below</h4>
				</div>
			);
		}
	};

	const renderCompanyList = () => {
		if (!showStep2b) {
			return (
				<CompaniesList items={candidates} selectCompany={(item) => selectCompany(item)} />
			)
		}
	};

	const renderStep2bHeader = () => {
		if (showStep2b) {
			return (
				<div className='animated fadeIn slow w-full text-center mt-6 mb-10'>
					<h1>Are these your company activities & revenue?</h1>
					<h4>We want to be sure we got the right details to provide your with an proper advise</h4>
				</div>
			);
		}
	};

	const renderStep2b = () => {
		if (showStep2b) {
			return (
				<div className='animated fadeInUp self-center flex flex-col'>
					<div className='bg-white shadow rounded py-4 px-5 self-center z-10' style={{width: '430px'}}>
						<img src={DetailsCardImage}/>
					</div>
					<div className='self-center mt-8 z-0' style={{width: '250px'}}>
						<DefaultButton width='250px' height='40px' onClick={() => gotoAdvise()}>Continue</DefaultButton>
					</div>
				</div>
			)
		}
	};

	const showResults = () => {
		return (
			<div className='flex flex-col justify-center'>
				{candidates.length > 0 ? renderResultsHeader() : renderNoResultsHeader()}
				{ renderCompanyList() }
				{ renderStep2bHeader() }
				{ renderStep2b() }
			</div>
		);
	};

	return <div className='flex w-full flex-col'>{loading ? renderLoader() : showResults()}</div>;
};

export default OnboardingWizardStep2;

import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import { epics, reducers } from './modules';
import { createEpicMiddleware } from 'redux-observable';

const createStore = () => {
	const epicMiddleware = createEpicMiddleware();
	const store = reduxCreateStore(reducers, applyMiddleware(epicMiddleware));

	epicMiddleware.run(epics);

	return store;
};
export default createStore;

import React from 'react';
import { Link } from 'gatsby';
import Icons from '../../assets/images/icons.svg';

const MonitoringNavbar = () => {
	const menu = [
		{
			label: 'Monitoring',
			active: true,
			link: '/monitoring'
		},
		{
			label: 'Underwriting',
			active: false,
			link: '/underwriting'
		},
		{
			label: 'Reports',
			active: false,
			link: '/monitoring'
		},
		{
			label: 'Statistics',
			active: false,
			link: '/monitoring'
		},
	];

	function renderMenuItems() {
		return (
			<ul className='list-reset flex justify-end items-center'>
				{menu.map((item) => (
					<li className='mr-3 text-gray-600' key={item.label}>
						<Link
							className={`animated fadeIn text-base inline-block py-2 px-4 no-underline hover:text-pink-600 ${
								item.active ? 'text-gray-800' : ''
							}`}
							to={item.link}>
							{item.label}
						</Link>
					</li>
				))}
			</ul>
		);
	}

	return (
		<nav className='flex items-center justify-between flex-wrap bg-white w-full'>
			<div className='flex items-center flex-shrink-0 text-white mr-6 p-4 pl-6'>
				<Link className='no-underline hover:no-underline' to='/'>
					<svg className='navbar-logo' alt='Insify' style={{ width: 81, height: 33 }}>
						<use xlinkHref={`${Icons}#icon-logo`}></use>
					</svg>
				</Link>
			</div>

			<div className='flex-grow items-center w-auto block pt-0'>{renderMenuItems()}</div>
			<button className='animated fadeIn flex items-center border border-gray-300 rounded py-2 px-3 text-sm text-gray-900 mr-3'>
				<svg style={{ width: 15, height: 14 }} className='fill-current text-gray-600 inline-block mr-1'>
					<use xlinkHref={`${Icons}#icon-user`}></use>
				</svg>
				Logout
			</button>
		</nav>
	);
};

export default MonitoringNavbar;

import React from 'react';
import { useSelector } from 'react-redux';
import OnboardingWizardStep1 from './step1';
import OnboardingWizardStep2 from './step2';
// import OnboardingWizardSummary from './summary';

const OnboardingWizard = () => {
	const { activeWizardStep } = useSelector((state) => state.onboarding);

	const controlWizardSteps = (step) => {
		switch (step) {
			case 2:
				return <OnboardingWizardStep2 />;
			// case 3:
			// 	return <OnboardingWizardSummary />;
			case 1:
				return <OnboardingWizardStep1 />;
			default:
				return;
		}
	};

	return controlWizardSteps(activeWizardStep);
};

export default OnboardingWizard;

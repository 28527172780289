import React from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { actions } from '../state/modules/onboarding';
import { navigate } from 'gatsby';
import PlaceholderImage from '../assets/images/placeholder-product-details.png';

const OnboardingAdvisePage = () => {
	const dispatch = useDispatch();
	dispatch(actions.changeWizardStep(3));

	const navigateNext = () => {
		navigate('/onboarding-advise-selected');
	};

	return (
		<Layout className='flex flex-col'>
			<Seo title='Product details' />
			<div className='absolute z-0 ins-placeholder-wrapper text-center' style={{backgroundColor: '#cb0566'}}>
				<div
					className='ins-placeholder-product-details animated fadeIn slow'
					onClick={() => navigateNext()}
					style={{ backgroundImage: `url(${PlaceholderImage})` }}></div>
			</div>
		</Layout>
	);
};

export default OnboardingAdvisePage;

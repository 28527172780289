import React from 'react';
import Layout from '../components/layout';
import MonitoringNavBar from '../components/monitoring/navbar';
import Seo from '../components/seo';

const MonitoringPage = () => {
	return (
		<Layout className='flex flex-col'>
			<Seo title='Monitoring' />
			<header className='flex flex-col sm:flex-row w-full'>
				<MonitoringNavBar />
			</header>
			<main className='flex container w-11/12 align-middle'>
				<div className='flex w-full flex-col'>
					<div className='animated fadeIn slow w-full text-center mt-16'>
						<h1>Monitoring</h1>
					</div>
					<div className='bg-white w-full shadow-md rounded p-4'>
						<iframe
							style={{ width: '100%', height: '500px' }}
							scrolling='no'
							title='Insify Customer Monitoring'
							src='https://low-example.surge.sh'></iframe>
					</div>
				</div>
			</main>
		</Layout>
	);
};

export default MonitoringPage;

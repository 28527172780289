import React from 'react';
import { animated, useTrail } from 'react-spring';
import CompanyRow from './company-row';

const CompaniesList = (props) => {
	const { items, selectCompany } = props;

	const trail = useTrail(items.length, {
		config: { mass: 3, tension: 670, friction: 80 },
		from: { opacity: 0, transform: 'translate3d(0,100px,0)' },
		to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
	});

	return (
		<React.Fragment>
			{trail.map((props, index) => {
				return (
					<animated.div style={props}>
						<CompanyRow
							title={items[index].name}
							coc={items[index].identifiers[0].id}
							address={items[index].address}
							key={items[index].name + index}
							onClick={() => selectCompany(items[index])}
						/>
					</animated.div>
				);
			})}
		</React.Fragment>
	);
};

export default CompaniesList;

import React from 'react';
import DefaultButton from '../../buttons/default';

const CompanyRow = (props) => {
	return (
		<div
			className='flex w-full justify-between bg-white w-full shadow rounded py-4 px-5 mb-2 cursor-pointer'
			onClick={props.onClick}
			onKeyDown={props.onClick}
			role='button'
			tabIndex={0}>
			<div>
				<h3>{props.title || 'Unknown name'}</h3>
				<p>Kvk: {props.coc}</p>
				<p>{props.address}</p>
			</div>
			<div className='flex-shrink-0 pt-1'>
				<DefaultButton onClick={props.onClick}>select</DefaultButton>
			</div>
		</div>
	);
};

export default CompanyRow;

import React from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Topbar from '../components/wizards/onboarding/topbar';
import BackButton from '../components/buttons/back';
import { actions } from '../state/modules/onboarding';
import { navigate } from 'gatsby';
import PlaceholderImage from '../assets/images/placeholder-sign.png';

const OnboardingAdvisePage = () => {
	const dispatch = useDispatch();
	dispatch(actions.changeWizardStep(5));

	const navigateBack = () => {
		dispatch(actions.changeWizardStep(4));
		navigate('/onboarding-configure-summary');
	};

	const navigateNext = () => {
		dispatch(actions.changeWizardStep(6));
		navigate('/onboarding-finished');
	};

	return (
		<Layout className='flex flex-col'>
			<Seo title='Home' />
			<header className='flex flex-col sm:flex-row w-full'>
				<Topbar />
			</header>
			<div className='flex w-full align-left pt-4'>
				<BackButton onClick={() => navigateBack()}>back</BackButton>
			</div>
			<div className='absolute z-0 ins-placeholder-wrapper text-center'>
				<div
					className='ins-placeholder-sign animated fadeIn slow'
					onClick={() => navigateNext()}
					style={{ backgroundImage: `url(${PlaceholderImage})` }}></div>
			</div>
		</Layout>
	);
};

export default OnboardingAdvisePage;
